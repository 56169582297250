





















































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Session from '@/models/graphql/Session';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import DynamicTextComponent from '@/components/DynamicTextComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import Speaker from '@/models/graphql/Speaker';
import { format } from 'date-fns';
import { mixins } from 'vue-class-component';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import { Getter, State } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import PillWidget from '@/components/pill/PillWidget.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import TitleComponent from '@/components/TitleComponent.vue';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import speakerRole from '@/models/graphql/SpeakerRole';

@Component({
  components: {
    PillWidget,
    AvatarSoloWidget,
    DynamicTextComponent,
    TitleComponent,
  },
})
export default class SessionTimelineComponent
  extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @State
  protected dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  @State
  private selectedTzAbbreviation!: string;

  @Prop({ required: false, default: () => [] })
  private readonly data!: Session[];

  @Prop({ required: false, default: 4 })
  private readonly amount!: number;

  @Prop({ required: false, default: true })
  private readonly paginate!: boolean;

  @Prop({ required: false })
  private readonly title!: string;

  @Prop({ required: false })
  private readonly titleSize!: string;

  @Prop({ required: false })
  private readonly subtitle!: string;

  @Prop({ required: false, default: null })
  private readonly route!: string;

  private paginationEnabled = this.paginate && this.amount && this.amount < this.data.length;

  private nbItems = this.amount || 0;

  private FileResourceHelper = FileResourceHelper;

  private hideShowLessButton = false;

  private moreLessText = 'actions.view-all';

  get dates(): string[] {
    return this.data.map((session) => {
      if (session.startTime) {
        const startTime = DateTimeHelper.utcToZonedTimeDate(
          `${session.startTime}Z`,
          this.selectedTzName,
        );
        return format(
          startTime,
          `${this.$t('app.date.dayOfWeek')}, ${this.$t('app.date.defaultDateFormat')}`,
          { locale: this.dateLocale },
        );
      }
      return '';
    });
  }

  get times(): string[] {
    return this.data.map((session) => {
      if (session.startTime && session.endTime) {
        const startTime = DateTimeHelper.utcToZonedTimeDate(
          `${session.startTime}Z`,
          this.selectedTzName,
        );
        const endTime = DateTimeHelper.utcToZonedTimeDate(
          `${session.endTime}Z`,
          this.selectedTzName,
        );
        return `${format(startTime, `${this.$t('app.date.defaultTimeFormat')}`)}
       ${this.$t('app.date.dateTimeSeparator')}
       ${format(endTime, `${this.$t('app.date.defaultTimeFormat')}`)}
       ${!this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)
        || !(this.featureByKey(FeatureKeys.COMMUNITY_TIMEZONE_FEATURE)).enabled
    ? `(${this.selectedTzAbbreviation})`
    : ''}`;
      }
      return '';
    });
  }

  private get payloadOrderBy(): string|null {
    if (this.payload && 'orderBy' in this.payload
      && this.payload.orderBy
      && (this.payload.orderBy as string).length > 0
    ) {
      return this.payload.orderBy as string;
    }
    return null;
  }

  created(): void {
    if (this.payload
      && 'filter' in this.payload
      && this.payload.filter) {
      const orderBy = {};
      if (this.payloadOrderBy
        && !(this.payload.orderBy as string).includes('default')
        && !(this.payload.orderBy as string).includes('default')
      ) {
        let orderByValue = (this.payload.orderBy as string).split(',');
        orderByValue = orderByValue.filter((value: string) => !value.startsWith('_speakerRoleDisplaySequence'));
        Object.assign(orderBy, {
          orderBy: {
            value: orderByValue,
            type: GqlEntityOrderingType.SESSION_ORDERING,
          },
        });
      }
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.SESSION_FILTER,
            value: JSON.parse(this.payload.filter as string || '{}'),
          },
          ...orderBy,
        }),
        operation: 'session',
        fragmentName: 'sessionTimelineFragment',
        alias: this.storeName,
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
      );
    }
  }

  @Watch('data')
  dataLoaded(): void {
    this.$nextTick(() => {
      this.paginationEnabled = this.paginate && this.amount && this.amount < this.data.length;
      this.nbItems = this.amount || 0;

      if (this.data && this.data.length === 0) {
        this.$eventsBus.emit('on-hide-section-widget', this.widget.uid);
      }
    });
  }

  private orderedSpeakerRole(session: Session): speakerRole[] | null {
    if (session.speakerRoles && session.speakerRoles.length > 0) {
      if (this.payloadOrderBy && this.payloadOrderBy.length > 0) {
        const orderByValue = this.payloadOrderBy.split(',')
          .find((field: string) => field.startsWith('_speakerRoleDisplaySequence'));
        if (orderByValue === '_speakerRoleDisplaySequence_asc') {
          return [...session.speakerRoles].sort((a, b) => (a.displaySequence || 0) - (b.displaySequence || 0));
        } if (orderByValue === '_speakerRoleDisplaySequence_desc') {
          return [...session.speakerRoles].sort((a, b) => (b.displaySequence || 0) - (a.displaySequence || 0));
        }
      }
      return session.speakerRoles;
    }
    return null;
  }

  private speakerInfos(speaker: Speaker): string {
    const infos = [];
    if (speaker) {
      const fullName = [];
      if (speaker.prefix) {
        fullName.push(speaker.prefix);
      }
      if (speaker.firstName) {
        fullName.push(speaker.firstName);
      }
      if (speaker.lastName) {
        fullName.push(speaker.lastName);
      }
      if (speaker.suffix) {
        fullName.push(speaker.suffix);
      }
      infos.push(fullName.join(' '));
      const job = [];
      if (speaker.jobTitle) {
        job.push(speaker.jobTitle);
      }
      if (speaker.employerName) {
        job.push(speaker.employerName);
      }
      if (job.length === 1) {
        infos.push(...job);
      } else if (job.length === 2) {
        infos.push(job.join(` ${this.$t('actions.only-at')} `));
      }
    }
    return infos.join(', ');
  }

  private toggleMoreLess(): void {
    if (this.nbItems === this.data.length) {
      this.nbItems = this.amount;
      this.moreLessText = 'actions.view-all';
    } else {
      this.nbItems = this.data.length;
      this.moreLessText = 'actions.view-less';
    }
  }

  private handleUrl(uid: string): void {
    if (this.route) {
      let r = this.route;
      const matches = this.route.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        this.$router.push(`${r}${uid}`);
      } else if (r[r.length - 1] === '/') {
        this.$router.push(`${r}${uid}`);
      } else {
        this.$router.push(`${r}/${uid}`);
      }
    }
  }
}
